<template>
    <header class="header">
        <img
            src="@/assets/Ref_Buddy.jpg"
            class="header__image"
            alt="Ref Buddy logo"
        />
        <h1 class="header__title">Ref Buddy</h1>
    </header>
</template>
